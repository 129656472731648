<template>
  <div class="faq-container">
    <Header active="Faq"></Header>
    <div class="faq-content">
      <div class="top-image">
        <div class="title">FAQ</div>
        <div>Advice and answers from the Giftcard8 Team.</div>
      </div>
      <div class="faq-list">
        <el-collapse>
          <el-collapse-item title="Cannot use the app after update?">
            <template #title>
              <div class="question-icon">Q</div>
              Cannot use the app after update?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <span>Remember your password</span>, delete the app and go back
                to Google play store or App store to download it again.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How to create my account?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                Created <span>user name</span>, <span>strong password</span>,
                select your <span>country</span> and enter the invitation code.
                <p>
                  Tips: If you get Giftcard8 through a friend invitation, you
                  can fill in your friend invitation code, if you don't have a
                  friend invitation, just leave it blank. Click “Continue” and
                  that’s it.
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How to sell my gift card?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>
                  Simply click the <span>Sell now for cash</span>, upload the
                  card picture and submit the order for review. If you only have
                  gift card pin number, you can take the screenshot of your
                  code, then submit the picture.
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              What Gift Cards I Can Sell On Giftcard8?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                All types of cards we accept have been listed on the
                <span>APP home page</span> and <span>website home page</span>.
                Swipe up the main page to get all rates. We will update the
                latest exchange rate every day.
                <p>
                  Slide your finger down and select the gift card you want to
                  find.
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              What is info Gift card?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                A lot of people, after they redeem their gift card, say “we need
                more info to redeem your card”.It does not mean that your card
                is used, it means that your card has not been activated
                properly, your money is still in your card, but the system
                cannot redeem your card. Giftcard8 can't accept <span>info gift card.</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How long the processing will take?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                It’s determined by the card. Generally, These cards will be
                processed in
                <span>1-10 minutes</span>: Steam\ Google\ Apple\ Amazon\ Razor
                Gold\ Xbox . And these cards will be processed in
                <span>30-90 minutes</span>: AMEX 3779\ MACY\ Vanilla\ Sephora\
                Nordstrom\ Visa. If you wait too long, please contact our
                <span>customer service.</span>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How long does it take for the withdrawal to arrive?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                After you submit the withdrawal application, money will arrive
                in your bank account within <span>10 minutes</span>. If you
                don't receive your money in more than 24 hours, please contact
                <span>customer service</span>.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How can I get rewards in giftcard8?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>
                  （1）₦500/GH₵5 reward for every
                  <span>new Giftcard8 user</span>
                </p>
                <p>
                  （2）₦500/GH₵5 will be rewarded when the
                  <span>first successful transaction</span> amount >=$20
                </p>
                <p>（3）₦500/GH₵5 reward for <span>inviting friends</span></p>
                <p>
                  （4）₦2000/GH₵20 reward for reaching
                  <span>500 transaction amount.</span>
                </p>
                <p>
                  （5）₦1000/GH₵10 reward for reaching
                  <span>300 transaction amount.</span>
                </p>
                <p>
                  （6）₦500/GH₵5 reward for reaching
                  <span>200 transaction amount.</span>
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Why I can't withdraw my rewards?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                When your reward balance reaches 1500naira /15cedi, your reward
                balance system will automatically send you your available
                balance.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              What should I do if the bank I use is not supported?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                If the bank card you use is not supported by our website, please
                contact <span>customer service</span>, customer service will
                solve the problem.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How to invite friends for the rewards?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>
                  Click “<span>Invitation for rewards</span>” on the Giftcard8
                  app home page, learn more about inviting friends, swipe to the
                  bottom, copy the link and your own
                  <span>six-digit invitation code</span>, send it to your
                  friends. You will receive the reward money after your friend
                  complete the <span>first success transaction</span> with us.
                </p>
                <!-- <el-carousel height="550px" indicator-position="none">
                  <el-carousel-item v-for="item in swiperList" :key="item.id">
                    <el-image :src="item.src" fit="fill"></el-image>
                  </el-carousel-item>
                </el-carousel> -->
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Why did I invite my friends but didn't get referral rewards?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                You will receive the reward money after your friend complete the
                <span>first success transaction</span> with us.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Withdraw My Money?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>（1）Select your wallet and click “Withdraw”.</p>
                <p>
                  （2）Add your bank details, enter the amount you want to
                  withdraw and click “withdraw”.
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Minimum account withdrawal amount?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p><span>1000</span> Naira.(for Nigerian users)</p>
                <p><span>10</span> cedi.(for Ghanaian users)</p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How can I check the exchange rate?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                The whole <span>rate list</span> will display on the
                <span>home page</span>
                and will update at any time, check the current rate on home page
                and calculate your money now!
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Can I get available help easily?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                Sure, there are two ways you can get effective help. You can
                talk to us using <span>the in-app live chat</span> or contact us
                WhatsApp: +8618179849917. We will sincerely listen to your
                proposals, concerns and questions.
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              How can I start my Check-in on Giftcard8?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>
                  1.At the bottom of Giftcard8- ”<span>Account</span>”
                </p>
                <el-image
                  :src="require('../../assets/images/fqa_checkin_01.png')"
                ></el-image>
                <p>
                  2.Click “<span>Check-in</span>” button
                </p>
                <el-image
                  :src="require('../../assets/images/fqa_checkin_02.png')"
                ></el-image>
                <p>3.Check your check-in <span>date</span></p>
                <el-image
                  :src="require('../../assets/images/fqa_checkin_03.png')"
                ></el-image>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              Can I get reward for Check-in?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>1.First check-in successfully, Get <span>₦100/₵1.</span></p>
                <p>
                  2.Continue to complete 7 days of check-in, Get
                  <span>₦200/₵2.</span>
                </p>
                <p>
                  3.Interruptions or less than <span>7 days</span> will not be
                  rewarded.
                </p>
                <p>
                  4.Seven days as a cycle, <span>new cycle</span> begins on the
                  <span>
                    eighth day.
                  </span>
                </p>
                <p>5.Check-in rewards in your <span>Reward Balance.</span></p>
                <p>
                  6.Giftcard8 reserved all rights.
                </p>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item>
            <template #title>
              <div class="question-icon">Q</div>
              What should I do if I forget my password?
            </template>
            <div class="faq-items-answer">
              <div class="answer-icon">A</div>
              <div class="answer-text">
                <p>
                  *If you bind your email, we will send a
                  <span>verification code</span> to your email for verification.
                </p>

                <p>
                  *Don't worry about that, please contact our
                  <span>online customer care</span> and provide one of the
                  following information: <span>user name</span>;
                  <span>binding email</span>; <span>number</span>. The next step
                  is to leave it to them and you just have to be patient.
                </p>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-backtop :bottom="100">
        <div
          style="
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          "
        >
          UP
        </div>
      </el-backtop>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";

export default defineComponent({
  name: "Faq",
  components: { Header, Footer },
  setup() {
    const gifImgSrc = reactive(require("../../assets/images/2.gif"));
    const swiperList = reactive([
      {
        id: 1,
        src: require("../../assets/images/swiper_01.png"),
      },
      {
        id: 2,
        src: require("../../assets/images/swiper_02.png"),
      },
      {
        id: 3,
        src: require("../../assets/images/swiper_03.png"),
      },
    ]);
    onMounted(() => {
      document.documentElement.scrollTop = 0;
    });
    return { gifImgSrc, swiperList };
  },
});
</script>

<style lang="less">
.faq-container {
  position: relative;
  background-color: rgba(247, 247, 247, 1);
  .faq-content {
    .top-image {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-image: url("../../assets/images/top_img_bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      font-size: 20px;
      .title {
        margin-bottom: 36px;
        font-size: 60px;
      }
    }
    .faq-list {
      margin: 20px 200px;
      .el-collapse {
        border: none;
        .el-collapse-item {
          margin-bottom: 10px;
          padding: 20px 30px;
          background-color: #fff;
          border-radius: 8px;
          .el-collapse-item__header {
            color: #333333;
            font-size: 26px;
            font-weight: bold;
            border: none;
            .question-icon {
              width: 28px;
              height: 28px;
              margin-right: 10px;
              line-height: 28px;
              text-align: center;
              color: #fff;
              font-weight: 100;
              background-color: rgba(0, 2, 73, 1);
              border-radius: 4px;
            }
          }
          .el-collapse-item__wrap {
            border: none;
          }
          .el-collapse-item__content {
            padding: 0;

            .faq-items-answer {
              display: flex;
              align-items: flex-start;
              margin-top: 20px;
              font-size: 20px;
              .answer-icon {
                width: 28px;
                height: 28px;
                margin-right: 10px;
                line-height: 28px;
                text-align: center;
                color: #fff;
                background-color: rgba(170, 170, 170, 1);
                border-radius: 4px;
              }
              .answer-text {
                flex: 1;
                color: #666666;
                text-align: left;
                span {
                  color: #0f4392;
                  font-weight: bold;
                }
                .image-box {
                  display: flex;
                  justify-content: center;
                }
                .el-image {
                  margin-top: 20px;
                }
                .el-carousel {
                  .el-carousel__item {
                    display: flex;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
